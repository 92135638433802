import React, { useState } from 'react';
import './GasChain.css';

const GasChain = ( { change }) => {
	const [chain, setChain] = useState("ETH");

    const handleSelect = (chain) => {
        setChain(chain);
		change(chain);
	};

	return (
		<>
            <select name="chains" id="chains" 
                value={chain}
                onChange={e => handleSelect(e.target.value)}> 
                <option value="ETH">Ethereum Mainnet</option>
                <option value="BSC">Binance Smart Chain</option>
                <option value="AVAX">Avalanche</option>
                <option value="BASE">Base</option>
                <option value="POLYGON">Polygon</option>
                <option value="ARBITRUM">Arbitrum</option>
                <option value="OPTIMISM">Optimism</option>
            </select>
		</>
	);
};

export default GasChain;