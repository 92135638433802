import React, { useState, useEffect } from 'react';
import './GasButton.css';

export default function GasButton( { handleClick, hidden }) {

    if (!hidden) {
        return (
            <>
                <br></br>
                <div>
                    <button onClick={handleClick} className='glowing-btn'>
                        <span className='glowing-txt'>
                            G
                            <span className='faulty-letter'>A</span>
                            S
                        </span>
                    </button>
                </div>
                <br></br>
            </>
        );
    }
}