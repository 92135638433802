import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../App/App.css';
import AddressForm from '../AddressForm/AddressForm';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GasButton from '../GasButton/GasButton';
import './GasForm.css';
import GasChain from '../GasChain/GasChain';

const initialValues = {
	chain: 'ETH',
	address: '',
	startDate: '',
	endDate: '',
	date: ''
}

const GasForm = ({ change, showButton }) => {
	const [state, setState] = useState(initialValues);

	const handleChainChange = e => {
		// console.log("e:" + e);
		// console.log("e.target:" + e.target);
		// let { value, name } = e.target;
		let value = e;
		let name = "chain";
		// console.log("name: " + name);
		// console.log("value: " + value);
		handleChange(name, value);
	};

	const handleAddressChange = e => {
		// console.log("e:" + e);
		// console.log("e.target:" + e.target);
		// let { value, name } = e.target;
		let value = e;
		let name = "address";
		// console.log("name: " + name);
		// console.log("value: " + value);
		handleChange(name, value);
	};

	const handleStartDateChange = e => {
		// console.log("e:" + e);
		// console.log("e.target:" + e.target);
		let value = e;
		let name = "startDate";
		// console.log("name: " + name);
		// console.log("value: " + value);
		handleChange(name, value);
	};

	const handleEndDateChange = e => {
		// console.log("e:" + e);
		// console.log("e.target:" + e.target);
		// let { value, name } = e.target;
		let value = e;
		let name = "endDate";
		// console.log("name: " + name);
		// console.log("value: " + value);
		handleChange(name, value);
	};

	const handleChange = (name, value) => {
		const date = new Date();
		setState({
			...state,
			[name]: value,
			date
		});
		// console.log(state);
		// change(state);
	};

	const handleSubmit = () => {
		change(state);
		// setState(initialValues);
	};

	return (
		<>
			<div className="row">
				<div className="col m6 s12 l12">
					<label htmlFor="chain">Chain: </label>
					<GasChain change={handleChainChange}></GasChain>
				</div>
			</div>
			<div className="row">
				<div className="col m6 s12 l12">
					<label htmlFor="address">Wallet Address: </label>
					<AddressForm change={handleAddressChange} />
				</div>
			</div>
			<div className="row">
				<div className="col m6 s12 l12">
					<label htmlFor="startDate">Start Date: </label>
					<DatePicker 
						id="startDate"
						name="startDate" 
						selected={state.startDate} 
						onChange={handleStartDateChange}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col m6 s12 l12">
					<label htmlFor="endDate">End Date: </label>
					<DatePicker 
						id="endDate"
						name="endDate" 
						selected={state.endDate} 
						onChange={handleEndDateChange}
					/>
				</div>
			</div>
			<div className="center">
				<GasButton
					id="gas-btn"
					className="calculate-btn"
					type="button"
					disabled={state.weight === ''}
					handleClick={handleSubmit}
					hidden={!showButton}
				>
					Calculate Gas
				</GasButton>
			</div>
		</>
	);
};

GasForm.propTypes = {
	change: PropTypes.func.isRequired
};

export default GasForm;