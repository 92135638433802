import React from 'react';
import './GasExportButton.css';

const GasExportButton = ( { state, data, hidden }) => {

    const handleClick = () => {
        const blob = new Blob([data.csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const startDate = cleanDateString(state.startDate);
        const endDate = cleanDateString(state.endDate);
        a.download = "tx-" + state.chain + "-" + state.address + "-" + startDate + "-" + endDate + '.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
	};

    const cleanDateString = (date) => {
        var newDate = new Date(date).toLocaleDateString();
        return newDate;
    }

    if (!hidden) {
        return (
            <>
                <button onClick={handleClick} className='glowing-btn'>
                    <span className='glowing-txt'>
                        D
                        <span className='faulty-letter'>A</span>
                        T
                        <span className='faulty-letter'>A</span>
                    </span>
                </button>
            </>
        );
    }
};

export default GasExportButton;