import React from 'react';
import './GasDisclaimer.css';


export default function GasDisclaimer({ hidden }) {

    if (!hidden) {
        return (
            <>
                <div className="disclaimer">
                    * USD values based on daily price data *
                </div>
            </>
        );
    }
}