import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import './GasTable.css';


export default function GasTable({ data, hidden }) {
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const newRows = [
            ['Total Transactions', data.totalTx],
            ['Gas Transactions', data.validTx],
            ['Gas in ' + data.ticker, data.gasX],
            ['Gas in USD', data.gasUSD],
        ];
        setRows(newRows);
    },[data]);

    if (!hidden) {
        return (
            <>
                <Table striped bordered hover>
                    <tbody>
                            {/* <tr>
                                <td>{rows[0][0]}</td>
                                <td>{rows[0][1]}</td>
                            </tr> */}
                            <tr>
                                <td>{rows[1][0]}</td>
                                <td>{rows[1][1]}</td>
                            </tr>
                            <tr>
                                <td>{rows[2][0]}</td>
                                <td>{rows[2][1]}</td>
                            </tr>
                            <tr>
                                <td>{rows[3][0]}</td>
                                <td>{rows[3][1]}</td>
                            </tr>
                    </tbody>
                </Table>
            </>
        );
    }
}