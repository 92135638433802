import React from 'react';
import './GasHeader.css';


export default function GasHeader() {

    return (
        <>
            <header className="header">
                    <div className="donations" >
                        <a className="icon" href="https://etherscan.io/address/0xf726940455b438e9A36BD6B6c859B422d19678F0" target="_blank" rel="noreferrer">
                            <img id="heart-icon" className="heart-icon" alt="donate" draggable="false" loading="lazy" decoding="async" src="/heart.cyan.png"/>
                            <img id="heart-icon-hover" className="heart-icon" alt="donate" draggable="false" loading="lazy" decoding="async" src="/heart.cyan.hover.png"/>
                        </a> 
                    </div>
                    <div className="title">
                        GASTER
                    </div>
                    <div className="socials">
                        <a className="icon" href="https://x.com/fastergas" target="_blank" rel="noreferrer">
                            <img id="x-icon" className="x-icon" alt="X" draggable="false" loading="lazy" decoding="async" src="/x.cyan.png"/>
                            <img id="x-icon-hover" className="x-icon" alt="X" draggable="false" loading="lazy" decoding="async" src="/x.cyan.hover.png"/>
                        </a>
                    </div>
            </header>
        </>
    );
}