import React, { useState } from 'react';
import axios from "axios";
import logo from '../../resources/logo.svg';
import './App.css';
import GasForm from "../GasForm/GasForm";
import GasTable from "../GasTable/GasTable";
import GasDisclaimer from "../GasDisclaimer/GasDisclaimer";
import GasExportButton from "../GasExportButton/GasExportButton";
import GasFooter from "../GasFooter/GasFooter";
import GasHeader from "../GasHeader/GasHeader";
import { getData } from '../../helpers/localStorage';
import { v4 as uuidv4 } from 'uuid';

const App = () => {
  const initialState = () => getData('data') || [];
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const [showData, setShowData] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleChange = val => {
    val.id = uuidv4();
    setState(val);
    getGas(val);
  };

  const getGas = (val) => {
    const body = JSON.stringify(val);
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    setShowData(false);
    setShowSpinner(true);
    axios.post('https://gaster.io/gas', body, config)
    .then((data) => {   
        setData(data.data);
        setShowData(true);
        setShowSpinner(false);
    })
  }

  return (
    <div className="App">
      <header className="App-header">
        <GasHeader/>
        <GasForm change={handleChange} showButton={!showSpinner}/>
        <GasTable data={data} hidden={!showData}/>
        <GasDisclaimer hidden={!showData}/>
        <GasExportButton state={state} data={data} hidden={!showData}/>
        <img src={logo} className="App-logo" alt="logo" hidden={!showSpinner}/>
      </header>
      {/* <GasFooter/> */}
    </div>
  );
}

export default App;
