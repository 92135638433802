import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AddressForm.css';

const initialValues = {
	address: ''
}

const AddressForm = ({ change }) => {
	const [state, setState] = useState(initialValues);

	const handleChange = e => {
		// console.log("e:" + e);
		// console.log("e.target:" + e.target);
		let { value, name } = e.target;
		setState({
			[name]: value
		});
		change(value);
	};

	return (
		<>
			<input
				id="address"
				name="address"
				type="text"
				minLength="42"
				maxLength="42"
				size="42"
				placeholder="0x0000000000000000000000000000000000000000"
				value={state.address}
				onChange={handleChange}
			/>
		</>
	);
};

AddressForm.propTypes = {
	change: PropTypes.func.isRequired
};

export default AddressForm;